import React from "react"
import {
  useDisclosure,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  VStack,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Avatar,
  Image,
  Container,
  useColorModeValue,
  Center,
  OrderedList,
  ListItem,
} from "@chakra-ui/react"
import logo from "../assets/zulfah-logo.png"
import guyWithLaptop from "../assets/guy-with-laptop.png"
import { Android, BriefCase, Bookmark, Charts } from "../assets/icons/index"
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai"
import { Footer } from "../components/Footer"
import MainNavigation from "../components/Navigation"
import { Link as ReactRouterLink } from "react-router-dom"
import MemberB from "../assets/rosh1.png"
import MemberA from "../assets/rosh2.png"
import MemberC from "../assets/rosh3.png"
import Interswitch from "../assets/_interswitch.jpeg"
import Flutterwave from "../assets/flutterwave.png"
import Meristem from "../assets/meristem.png"
import HerVest from "../assets/HerVest.png"
import App1 from "../assets/application1.png"
import { Hourglass, Tuition } from "../assets/icons"
import { BsArrowRight } from "react-icons/bs"
import { FAQs } from "../components/FAQs"
import { ApplicationCTA } from "../components/ApplicationCTA"
import { tableOfContent, students_reviews } from "../utils/lists"
import coffee from "../assets/coffee.webp"
import school1 from "../assets/school1.jpg"
import companyWork from "../assets/company-work.png"
import softwareEngineer from "../assets/trained-engineers.jpeg"
import productDesign from "../assets/productDesign.jpeg"
import devOps from "../assets/devops__.png"
import dataScience from "../assets/datascience__.png"
import mobileApp from "../assets/mobile.png"
import blockChain from "../assets/blockchain.png"
import dataSciEx from "../assets/dataSci.webp"
import DevOpsEx from "../assets/DevOps-scaled.webp"
import Web3Ex from "../assets/Web3Ex.jpeg"
import ProductEx from "../assets/ProductEx.jpeg"
import MobileEx from "../assets/MobileEx.png"

export default function AboutUs() {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: false })

  const team = [
    { name: "nerdy - knight", img: MemberA, linkedIn: "" },
    {
      name: "code - ninja",
      img: MemberB,
      linkedIn: "https://www.linkedin.com/in/abayomi-ajao-18458b7b/",
    },
    { name: "code - slayer", img: MemberC, linkedIn: "" },
  ]
  const Testimonial = ({ children }) => {
    return <Box>{children}</Box>
  }
  const TestimonialContent = ({ children }) => {
    return (
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"lg"}
        p={8}
        rounded={"xl"}
        align={"center"}
        pos={"relative"}
        _after={{
          content: `""`,
          w: 0,
          h: 0,
          borderLeft: "solid transparent",
          borderLeftWidth: 16,
          borderRight: "solid transparent",
          borderRightWidth: 16,
          borderTop: "solid",
          borderTopWidth: 16,
          borderTopColor: useColorModeValue("white", "gray.800"),
          pos: "absolute",
          bottom: "-16px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {children}
      </Stack>
    )
  }
  const TestimonialHeading = ({ children }) => {
    return (
      <Heading as={"h3"} fontSize={"xl"}>
        {children}
      </Heading>
    )
  }
  const TestimonialText = ({ children }) => {
    return (
      <Text
        textAlign={"center"}
        color={useColorModeValue("gray.600", "gray.400")}
        fontSize={"sm"}
      >
        {children}
      </Text>
    )
  }

  const outerBoxStyles = {
    boxSize: "250px",
    p: "10",
    background:
      "url(https://picsum.photos/id/1068/200/300) center/cover no-repeat",
  }

  const innerBoxStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    boxSize: "full",
    color: "white",
    textShadow: "0 0 20px black",
    fontWeight: "bold",
    fontSize: "20px",
  }

  const TestimonialAvatar = ({ src, name, title, company }) => {
    return (
      <Flex align={"center"} mt={8} direction={"column"}>
        <Avatar name={name} alt={name} mb={2} />
        <Stack spacing={-1} align={"center"}>
          <Text fontWeight={600}>{name}</Text>
          <Text
            fontSize={"sm"}
            color={useColorModeValue("gray.600", "gray.400")}
          >
            {title}
          </Text>
          <Text
            fontSize={"sm"}
            color={useColorModeValue("gray.600", "gray.400")}
          >
            {company}
          </Text>
        </Stack>
      </Flex>
    )
  }

  return (
    <Box position={"relative"}>
      <MainNavigation />
      <Modal isOpen={isOpen} size={"lg"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image
              boxSize='100%'
              objectFit='cover'
              src={App1}
              alt='Zulfah Applications are now open'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box paddingTop={{ base: "0px", lg: "none" }} bg={"white"}>
        <Stack
          pos={"relative"}
          h={{ base: "50vh", lg: "75vh" }}
          direction={{ base: "column", md: "row" }}
          style={{
            background:
              "linear-gradient(114.54deg,hsla(0,0%,100%,.5) 16.01%,rgba(244,244,226,.5) 48.74%,rgba(240,245,255,.5) 89.98%)",
            backdropFilter: "blur(4px)",
          }}
        >
          <Flex p={8} flex={1} align={"center"} justify={"center"}>
            <Stack alignItems={"center"} spacing={6} maxW='85%'>
              <Heading
                color={"brand.fuscia"}
                fontSize={{ base: "4xl", md: "4xl", lg: "6xl" }}
              >
                {/* Become a Skilled Software Engineer */}
                Join Our Innovative Tech School Today!
              </Heading>
              <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
                Gain the skills you need to land a job in the tech industry.
                <br />
                Zulfah Academy trains and mentors you to become an excellent
                engineer. Over 100 professionals trained.{" "}
                <a
                  href='https://www.youtube.com/watch?v=di7UHCMuZ0o'
                  target='_blank'
                  style={{ color: "#CF3A5E" }}
                >
                  Read stories
                </a>
              </Text>
              <ReactRouterLink to='/application' w={100}>
                <Button
                  display={{ lg: "none" }}
                  variant={"solid"}
                  bg={"brand.gold"}
                  size={"lg"}
                  width={{ base: "100%", lg: "150px" }}
                >
                  👉🏾 Apply now
                </Button>
              </ReactRouterLink>
              {/* {new Date().getTime() > new Date('2022-08-10').getTime()
                 ?  <Button
                          display={{ lg: "none" }}
                          variant={"solid"}
                          bg={"brand.gold"}
                          size={"lg"}
                          width={{ base: "100%", lg: "150px" }}
                    > 
                    <ReactRouterLink to="/application">👉🏾 Apply now</ReactRouterLink>
                    </Button>
                  : <Text style={{ float: "left" }} textColor={"#CF3A5E"}>
                    👉🏾 Applications will open 26th September </Text>
                  } */}

              {/* <Text
                style={{float: "left"}}
                textColor={"#CF3A5E"}>
              {
                (new Date().getMonth() + 1) === 9 && new Date().getDate() === 26 ?
                 "👉🏾 Applications currently open"  : " 👉🏾 Join waitlist here"
              
              }
              </Text> */}
            </Stack>
          </Flex>
          {/* coffe icon */}
          <Flex flex={1} justifyContent={"center"}>
            <Box
              shadow='2xl'
              boxSize={{ base: "0px", md: "100%" }}
              backgroundImage={coffee}
              backgroundSize={"cover"}
              backgroundPosition={"bottom"}
              backgroundRepeat={"no-repeat"}
              borderRadius={{ base: "0px", md: "0px" }}
            ></Box>
          </Flex>
          {/* coffe icon */}

          <Box
            display={{ base: "none", lg: "flex" }}
            alignItems={"center"}
            justifyContent={"space-around"}
            pos={"absolute"}
            bottom={"-10%"}
            left={"40px"}
            w={"1080px"}
            h={"155px"}
            bg={"brand.white"}
            borderRadius='12px'
            boxShadow={"lg"}
          >
            <HStack h={"70%"}>
              <Stack spacing={4} w={"220px"}>
                <Heading fontSize={"lg"} color={"brand.fuscia"}>
                  Application
                </Heading>
                <Text fontSize={"12px"} color={"brand.textgrey"}>
                  We screen all submissions to identify high-potential
                  candidates
                </Text>
              </Stack>
              <Divider
                orientation='vertical'
                bg={"brand.deepgrey"}
                color={"brand.deepgrey"}
              />
              <Stack spacing={4} pl={4} w={"220px"}>
                <Heading fontSize={"lg"} color={"brand.fuscia"}>
                  Interview - Selection{" "}
                </Heading>
                <Text fontSize={"12px"} color={"brand.textgrey"}>
                  You get a chance to convince us, then, we make our decision
                </Text>
              </Stack>
              <Divider orientation='vertical' />
              <Stack spacing={4} pl={4} w={"220px"}>
                <Heading fontSize={"lg"} color={"brand.fuscia"}>
                  Training
                </Heading>
                <Text fontSize={"12px"} color={"brand.textgrey"}>
                  After six months, you will earn the title "Skilled Software
                  Engineer"
                </Text>
              </Stack>
            </HStack>

            <VStack
              h={"80%"}
              color={"black"}
              alignItems={"center"}
              justifyContent={"center"}
              w={"140px"}
              bg={"brand.fuscia"}
              style={{ background: "#ecb32c" }}
              borderRadius='12px'
              boxShadow={"md"}
              cursor={"pointer"}
              display={{ base: "none", lg: "flex" }}
            >
              <BsArrowRight size={"30px"} />
              <ReactRouterLink to='/application'>
                <Text fontSize={"sm"}>Get started</Text>
              </ReactRouterLink>
            </VStack>
          </Box>
        </Stack>

        {/** 4 cards , equipped curriculum, Innovatiobe learning environment */}
        <Stack
          direction={{ base: "column", lg: "row" }}
          h={{ base: "auto", lg: "750px" }}
          pt={{ base: "0px", md: "0px", lg: "150px" }}
          justifyContent={"space-around"}
          alignItems={{ base: "center", lg: "baseline" }}
          spacing={6}
          maxW='100%'
          mb={{ base: "50px", lg: "0px" }}
        >
          <VStack
            textAlign={{ base: "justify", lg: "justify" }}
            spacing={{ base: "15px", lg: "50px" }}
            w={{ base: "75%", lg: "500px" }}
            mb={{ base: "40px", lg: "none" }}
            mt={{ base: "50px", lg: "none" }}
            justifyContent={{ base: "center", lg: "left" }}
            ml={{ base: "0px", lg: "60px" }}
          >
            {/* <Heading fontSize={"36px"}>
            Why Zulfah is the #1
            choice for you
            </Heading> */}
            <Text fontSize={"16px"}>
              <p>
                {" "}
                We deliver an intensive 6-9months program where our students
                learn <b>Frontend development</b>, <b>Backend development</b>,{" "}
                <b>Fullstack development</b>,<b>Mobile development</b>,{" "}
                <b>Product Design</b>, <b>Data Science</b>,{" "}
                <b>Web3 & Blockchain</b>
                &nbsp; and additional soft skills in an immersive environment.
              </p>
              <br />
              <p>
                Through an agile delivery methodology, mentor matching and
                leadership modules, our learners are equipped with techniques
                and a leadership mindset that enables them to work competitively
                in any team globally.
              </p>
              <br />
              <p>
                As part of our curriculum, we expose our engineers to real-life
                applications and projects. During these projects, we ensure they
                are well-equipped to develop programs individually and in teams.
              </p>
              <p>
                This aspect of our program provides our learners with verifiable
                work experience and the confidence to excel in their jobs after
                the training. Our graduates are mentored by various veterans in
                the tech industry, we leverage on that network to match our
                graduate with partner companies.
              </p>
            </Text>
          </VStack>
          <SimpleGrid
            w={{ base: "75%", lg: "750px" }}
            h={{ base: "auto", lg: "500px" }}
            spacing={"20px"}
            justifyContent={"space-around"}
            columns={[1, 1, 2]}
          >
            <VStack
              borderRadius='12px'
              alignItems={"left"}
              justifyContent={"space-evenly"}
              p={4}
              style={{ background: "#ecb32d1c" }}
              w={{ base: "100%", md: "300px" }}
              height='235px'
            >
              <IconButton
                bg={"brand.fuscia"}
                style={{ background: "#4994a8" }}
                borderRadius={4}
                w={"9"}
                icon={<BriefCase />}
              />
              <Heading fontSize={"14px"}>Equipped Curriculum</Heading>
              <Text fontSize={"12px"}>
                We enforce industry standard and best practices that match the
                dynamics of the tech industry.
              </Text>
            </VStack>
            <VStack
              borderRadius='12px'
              alignItems={"left"}
              justifyContent={"space-evenly"}
              p={4}
              bg='brand.lightpink'
              w={{ base: "100%", md: "300px" }}
              height='235px'
            >
              <IconButton
                bg={"brand.fuscia"}
                borderRadius={4}
                w={"9"}
                icon={<Bookmark />}
              />
              <Heading fontSize={"14px"}>
                Complete hands-on coding projects with personalized feedback
              </Heading>
              <Text fontSize={"12px"}>
                Enhances tactical problem-solving techniques and gives a feel of
                reality.
              </Text>
            </VStack>
            <VStack
              borderRadius='12px'
              alignItems={"left"}
              justifyContent={"space-evenly"}
              p={4}
              bg='brand.lightpink'
              w={{ base: "100%", md: "300px" }}
              height='235px'
            >
              <IconButton
                bg={"brand.fuscia"}
                borderRadius={4}
                w={"9"}
                icon={<Android />}
              />
              <Heading fontSize={"14px"}>
                Monthly session with Industry experts
              </Heading>
              <Text fontSize={"12px"}>
                Opportunity to engage tech veterans, network, and gain
                mentorship.
              </Text>
            </VStack>
            <VStack
              borderRadius='12px'
              alignItems={"left"}
              justifyContent={"space-evenly"}
              p={8}
              style={{ background: "#4994a82e" }}
              w={{ base: "100%", md: "300px" }}
              height='235px'
            >
              <IconButton
                style={{ background: "#cf3a5d" }}
                borderRadius={4}
                w={"9"}
                icon={<Charts />}
              />
              <Heading fontSize={"14px"}>Innovative learning culture</Heading>
              <Text fontSize={"12px"}>
                Peer-to-peer learning, with collaborative approach. And guess
                what? "Lunch is always on the house."
              </Text>
            </VStack>
          </SimpleGrid>
        </Stack>
        {/** 4 cards , equipped curriculum, Innovatiobe learning environment */}

        {/*  Meet the Team */}
        <Stack
          w={"100%"}
          alignItems={"center"}
          paddingTop={{ base: "50px", lg: "30px" }}
          bg={"whiteAlpha.100"}
          // style={{border:"2px solid red"}}
        >
          <Heading
            w={"80%"}
            display={{ base: "none", lg: "block" }}
            textAlign={{ base: "center", lg: "center" }}
          >
            Immersive bootcamps strategically designed
            <br /> to launch your tech career.
          </Heading>
          <br />
          <br />
          <Flex
            direction={{ base: "column", lg: "row" }}
            width={{ base: "80%", lg: "80%" }}
            justifyContent={"space-around"}
            alignContent={"center"}
            display={{ base: "none", lg: "flex" }}
          >
            <Stack
              spacing={{ base: 4, lg: 8 }}
              marginBottom={{ base: 9, lg: 0 }}
            >
              <Box
                maxW='xs'
                h='300px'
                borderWidth='1px'
                borderRadius='lg'
                overflow='hidden'
                bgColor={"#050794"}
              >
                <Image src={softwareEngineer} h='200px' alt='' />

                <Box p='6'>
                  <Box display='flex' alignItems='baseline'>
                    {/* <Badge borderRadius='full' px='2' colorScheme='teal'>
                          New
                        </Badge> */}
                    <Box
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='xs'
                      textTransform='uppercase'
                      ml='2'
                    >
                      {/* {property.beds} beds &bull; {property.baths} baths */}
                    </Box>
                  </Box>

                  <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                  >
                    {/* {property.title} */}
                  </Box>

                  <Box>
                    {/* {property.formattedPrice} */}
                    <Box as='span' color='white' fontSize='lg'>
                      <Center> Software Engineering</Center>
                    </Box>
                  </Box>

                  {/* <Box display='flex' mt='2' alignItems='center'>
                        {Array(5)
                          .fill('')
                          .map((_, i) => (
                            <StarIcon
                              key={i}
                              color={i < property.rating ? 'teal.500' : 'gray.300'}
                            />
                          ))}
                        <Box as='span' ml='2' color='gray.600' fontSize='sm'>
                          {property.reviewCount} reviews
                        </Box>
                      </Box> */}
                </Box>
              </Box>
              {/* <Box
               boxSize={"400px"}
               backgroundImage=""
               backgroundSize={"contain"}
               backgroundPosition="center"
               backgroundRepeat="no-repeat"
                borderRadius={"500px"}
              >
                 <Image src={softwareEngineer}  /> 

                  <Box p='6'  >
                    
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={1}
                      textAlign='center'
                   
                    >
                    Software Engineering
                    </Box>
                  </Box>
              </Box>     */}
            </Stack>

            <Stack
              spacing={{ base: 4, lg: 8 }}
              marginBottom={{ base: 9, lg: 0 }}
            >
              <Box
                maxW='xs'
                h='300px'
                borderWidth='1px'
                borderRadius='lg'
                overflow='hidden'
                bgColor={"#0C8F54"}
              >
                <Image src={devOps} h='200px' alt='' />

                <Box p='6'>
                  <Box display='flex' alignItems='baseline'>
                    {/* <Badge borderRadius='full' px='2' colorScheme='teal'>
                          New
                        </Badge> */}
                    <Box
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='xs'
                      textTransform='uppercase'
                      ml='2'
                    >
                      {/* {property.beds} beds &bull; {property.baths} baths */}
                    </Box>
                  </Box>

                  <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                  >
                    {/* {property.title} */}
                  </Box>

                  <Box>
                    {/* {property.formattedPrice} */}
                    <Box as='span' color='white' fontSize='lg'>
                      <Center>Mobile Engineering</Center>
                    </Box>
                  </Box>

                  {/* <Box display='flex' mt='2' alignItems='center'>
                        {Array(5)
                          .fill('')
                          .map((_, i) => (
                            <StarIcon
                              key={i}
                              color={i < property.rating ? 'teal.500' : 'gray.300'}
                            />
                          ))}
                        <Box as='span' ml='2' color='gray.600' fontSize='sm'>
                          {property.reviewCount} reviews
                        </Box>
                      </Box> */}
                </Box>
              </Box>
              {/* <Box
               boxSize={"400px"}
               backgroundImage=""
               backgroundSize={"contain"}
               backgroundPosition="center"
               backgroundRepeat="no-repeat"
                borderRadius={"500px"}
              >
                 <Image src={mobileApp} />

                  <Box p='6'>
                    
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={1}
                    >
                   Mobile Development
                    </Box>
                  </Box>
                </Box>     */}
            </Stack>

            <Stack
              spacing={{ base: 9, lg: 8 }}
              marginBottom={{ base: 9, lg: 0 }}
            >
              <Box
                maxW='xs'
                h='300px'
                borderWidth='1px'
                borderRadius='lg'
                overflow='hidden'
                bgColor={"#7C01A7"}
              >
                <Image src={productDesign} h='200px' alt='' />

                <Box p='6'>
                  <Box display='flex' alignItems='baseline'>
                    {/* <Badge borderRadius='full' px='2' colorScheme='teal'>
                          New
                        </Badge> */}
                    <Box
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='xs'
                      textTransform='uppercase'
                      ml='2'
                    >
                      {/* {property.beds} beds &bull; {property.baths} baths */}
                    </Box>
                  </Box>

                  <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                  >
                    {/* {property.title} */}
                  </Box>

                  <Box>
                    {/* {property.formattedPrice} */}
                    <Box as='span' color='white' fontSize='lg'>
                      <Center>Product Design</Center>
                    </Box>
                  </Box>

                  {/* <Box display='flex' mt='2' alignItems='center'>
                        {Array(5)
                          .fill('')
                          .map((_, i) => (
                            <StarIcon
                              key={i}
                              color={i < property.rating ? 'teal.500' : 'gray.300'}
                            />
                          ))}
                        <Box as='span' ml='2' color='gray.600' fontSize='sm'>
                          {property.reviewCount} reviews
                        </Box>
                      </Box> */}
                </Box>
              </Box>
              {/* <Box
               boxSize={"400px"}
               backgroundImage=""
               backgroundSize={"contain"}
               backgroundPosition="center"
               backgroundRepeat="no-repeat"
                borderRadius={"500px"}
              >
                  <Image src={productDesign} />
                  <Box p='6'>
                    
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={1}
                      textAlign='center'
                    >
                    Product Designs
                    </Box>
                  </Box>
                </Box>     */}
            </Stack>
          </Flex>
        </Stack>
        {/*  Meet the Team */}

        {/*  Meet the Team */}
        <Stack
          w={"100%"}
          alignItems={"center"}
          paddingTop={{ base: "10px", lg: "50px" }}
          bg={"whiteAlpha.100"}
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            width={{ base: "80%", lg: "80%" }}
            justifyContent={"space-around"}
            alignContent={"center"}
            display={{ base: "none", lg: "flex" }}
          >
            <Stack
              spacing={{ base: 4, lg: 8 }}
              marginBottom={{ base: 9, lg: 0 }}
            >
              {/* faruq */}
              <Box
                maxW='xs'
                h='300px'
                borderWidth='1px'
                borderRadius='lg'
                overflow='hidden'
                bgColor={"#A78700"}
              >
                <Image src={dataSciEx} h='200px' alt='' />

                <Box p='6'>
                  <Box display='flex' alignItems='baseline'>
                    {/* <Badge borderRadius='full' px='2' colorScheme='teal'>
                          New
                        </Badge> */}
                    <Box
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='xs'
                      textTransform='uppercase'
                      ml='2'
                    >
                      {/* {property.beds} beds &bull; {property.baths} baths */}
                    </Box>
                  </Box>

                  <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                  ></Box>

                  <Box>
                    <Box as='span' color='white' fontSize='lg'>
                      <Center>Data Science</Center>
                    </Box>
                  </Box>

                  {/* <Box display='flex' mt='2' alignItems='center'>
                        {Array(5)
                          .fill('')
                          .map((_, i) => (
                            <StarIcon
                              key={i}
                              color={i < property.rating ? 'teal.500' : 'gray.300'}
                            />
                          ))}
                        <Box as='span' ml='2' color='gray.600' fontSize='sm'>
                          {property.reviewCount} reviews
                        </Box>
                      </Box> */}
                </Box>
              </Box>

              {/* <Box
               boxSize={"300px"}
               backgroundImage=""
               backgroundSize={"contain"}
               backgroundPosition="center"
               backgroundRepeat="no-repeat"
                borderRadius={"500px"}
              >
                 <Image src={softwareEngineer}  /> 

                  <Box p='6'  >
                    
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={1}
                      textAlign='center'
                   
                    >
                    Software Engineering
                    </Box>
                  </Box>
              </Box>     */}
            </Stack>

            <Stack
              spacing={{ base: 4, lg: 8 }}
              marginBottom={{ base: 9, lg: 0 }}
            >
              <Box
                maxW='xs'
                h='300px'
                borderWidth='1px'
                borderRadius='lg'
                overflow='hidden'
                bgColor={"#A74F46"}
              >
                <Image src={DevOpsEx} h='200px' alt='' />

                <Box p='6'>
                  <Box display='flex' alignItems='baseline'>
                    <Box
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='xs'
                      textTransform='uppercase'
                      ml='2'
                    ></Box>
                  </Box>

                  <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                  ></Box>

                  <Box>
                    <Box as='span' color='white' fontSize='lg'>
                      <Center>DevOps</Center>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* <Box
               boxSize={"400px"}
               backgroundImage=""
               backgroundSize={"contain"}
               backgroundPosition="center"
               backgroundRepeat="no-repeat"
                borderRadius={"500px"}
              >
                 <Image src={mobileApp} />

                  <Box p='6'>
                    
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={1}
                    >
                   Mobile Development
                    </Box>
                  </Box>
                </Box>     */}
            </Stack>

            <Stack
              spacing={{ base: 4, lg: 8 }}
              marginBottom={{ base: 9, lg: 0 }}
            >
              <Box
                maxW='xs'
                h='300px'
                borderWidth='1px'
                borderRadius='lg'
                overflow='hidden'
                bgColor={"#0E8F8F"}
              >
                <Image src={Web3Ex} h='200px' alt='' />

                <Box p='6'>
                  <Box display='flex' alignItems='baseline'>
                    <Box
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='xs'
                      textTransform='uppercase'
                      ml='2'
                    ></Box>
                  </Box>

                  <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                  ></Box>

                  <Box>
                    <Box
                      as='span'
                      textAlign='center'
                      color='white'
                      fontSize='lg'
                    >
                      <Center> Web3 Technology </Center>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* <Box
               boxSize={"400px"}
               backgroundImage=""
               backgroundSize={"contain"}
               backgroundPosition="center"
               backgroundRepeat="no-repeat"
                borderRadius={"500px"}
              >
                  <Image src={productDesign} />
                  <Box p='6'>
                    
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={1}
                      textAlign='center'
                    >
                    Product Designs
                    </Box>
                  </Box>
                </Box>     */}
            </Stack>
          </Flex>
        </Stack>
        {/*  Meet the Team */}

        {/** Software Bootcamp */}
        <Stack
          w={"100%"}
          alignItems={"center"}
          paddingTop={{ base: "0px", lg: "50px" }}
        >
          <Tabs
            w={"80%"}
            display={{ base: "grid", md: "flex" }}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            variant='unstyled'
          >
            <Stack
              w={"353px"}
              spacing={8}
              p={4}
              borderColor={"brand.lightgrey"}
              borderRadius={4}
            >
              <Heading
                fontSize={"16px"}
                py={4}
                color={"brand.lightertextgrey"}
                borderBottomWidth={"1px"}
                borderBottomColor={"brand.lightgrey"}
              >
                SOFTWARE ENGINEERING BOOTCAMP
              </Heading>
              <TabList
                spacing={4}
                display={"flex"}
                alignItems={"flex-start"}
                flexDirection={{ base: "row", md: "column" }}
              >
                <Tab b={3} pl={0} _selected={{ fontWeight: "bold" }}>
                  {" "}
                  Overview{" "}
                </Tab>
                <Tab pl={0} _selected={{ fontWeight: "bold" }}>
                  {" "}
                  Requirements
                </Tab>
                <Tab pl={0} _selected={{ fontWeight: "bold" }}>
                  Curriculum
                </Tab>
                <Tab pl={0} _selected={{ fontWeight: "bold" }}>
                  {" "}
                  Payment & Sponsorship{" "}
                </Tab>
                <Tab pl={0} _selected={{ fontWeight: "bold" }}>
                  {" "}
                  Next application
                </Tab>
              </TabList>
            </Stack>
            <TabPanels w={{ base: "100%", lg: "700px" }}>
              {tableOfContent.map((item) => (
                <TabPanel key={item.title}>
                  <Stack
                    spacing={8}
                    textAlign={{ base: "justify", lg: "left" }}
                    mt={{ base: "30px", lg: "0px" }}
                  >
                    <Heading fontSize={{ base: "20px", lg: "20px" }}>
                      <Flex
                        width={"100%"}
                        bg={"white"}
                        justifyContent={"center"}
                      >
                        <Stack
                          pos={"relative"}
                          // h={{ base: "650px", lg: "1059px" }}
                          w={{ base: "90%", lg: "100%" }}
                          direction={{ base: "column", md: "row" }}
                          borderRadius={{ base: "15px", lg: "none" }}
                        >
                          <TabPanels>
                            {/* Overview */}
                            <TabPanel>
                              <Tabs
                                variant='soft-rounded'
                                colorScheme='green'
                                justifyContent={"center"}
                              >
                                <TabList justifyContent={"center"}>
                                  <Tab>Software Engineering</Tab>
                                  <Tab>Mobile Engineering</Tab>
                                  <Tab>Product Design</Tab>
                                  <Tab>Data Science</Tab>
                                  <Tab>Devops</Tab>
                                  <Tab>Web3 Technology</Tab>
                                </TabList>

                                <TabPanels>
                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Our development & engineering courses are
                                      designed to make you world-class fit,
                                      teaching you the technical and
                                      professional skills hiring managers need
                                      most. You’ll attend classes led by
                                      industry experts who will teach you the
                                      foundations of full-stack development
                                      using HTML, CSS, JavaScript, Typescript,
                                      ReactJs. You’ll also develop web servers
                                      using NodeJs,ExpressJS, RDBMS including
                                      MySQL and PostgreSQL while and applying
                                      various fundamental computer science
                                      concepts in your development. Plus, you’ll
                                      have the chance to build and contribute to
                                      real products with a team of other
                                      learners that you can showcase in your
                                      portfolio.\n We promote collaborative
                                      learning, which will be seen in our usage
                                      of git, Trello, and other tools in the
                                      software engineering space. Through an
                                      agile delivery methodology, mentor
                                      matching, and leadership modules, our
                                      elite engineers are equipped with
                                      techniques and a leadership mindset that
                                      enables them to work competitively in any
                                      team globally.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      overflow={"scroll"}
                                    >
                                      Our Mobile engineering course provides a
                                      comprehensive overview of the principles,
                                      technologies, and best practices involved
                                      in developing mobile applications for
                                      various platforms such as iOS and Android.
                                      <br />
                                      The course typically covers topics such
                                      as:
                                      <br />
                                      <OrderedList>
                                        <ListItem>
                                          Introduction to mobile app
                                          development: Overview of mobile
                                          platforms, app life cycle, and
                                          development environments.
                                        </ListItem>
                                        <ListItem>
                                          User interface design: Creating
                                          intuitive and visually appealing
                                          interfaces for mobile apps.
                                        </ListItem>
                                        <ListItem>
                                          App architecture: Understanding
                                          architectural patterns and best
                                          practices for building scalable and
                                          maintainable mobile apps.
                                        </ListItem>
                                        <ListItem>
                                          Data management: Techniques for
                                          handling local data storage, working
                                          with remote APIs, and managing data
                                          synchronization.
                                        </ListItem>
                                        <ListItem>
                                          Performance optimization: Strategies
                                          to enhance app performance, reduce
                                          battery consumption, and optimize
                                          network usage.
                                        </ListItem>
                                        <ListItem>
                                          Testing and debugging: Methods for
                                          testing mobile apps, identifying and
                                          fixing bugs, and ensuring app
                                          reliability.
                                        </ListItem>
                                        <ListItem>
                                          Deployment and app store guidelines:
                                          Preparing apps for deployment,
                                          adhering to app store guidelines, and
                                          managing app updates.
                                        </ListItem>
                                      </OrderedList>
                                      This course aims to equip students with
                                      the necessary skills and knowledge to
                                      design, build, and deploy mobile apps
                                      effectively. Throughout the course,
                                      students will delve into the fundamentals
                                      of mobile development, including user
                                      interface design, app architecture, data
                                      management, and integration of key
                                      functionalities like location services,
                                      push notifications, and social media
                                      integration. <br />
                                      Throughout the course, students may work
                                      on hands-on projects and assignments,
                                      allowing them to apply the concepts
                                      learned and gain practical experience in
                                      mobile app development. They may also have
                                      opportunities to collaborate with
                                      classmates and receive feedback from
                                      instructors to improve their skills.{" "}
                                      <br />
                                      By the end of the mobile engineering
                                      course, students should have a solid
                                      foundation in mobile app development,
                                      enabling them to pursue careers as mobile
                                      app developers or continue their learning
                                      journey in more specialized areas such as
                                      game development, augmented reality, or
                                      mobile backend development.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      overflow={"scroll"}
                                    >
                                      The Product Design course offers a
                                      comprehensive overview of the principles,
                                      methodologies, and skills involved in
                                      designing successful products. This course
                                      aims to equip students with the necessary
                                      knowledge and techniques to create
                                      user-centered and innovative product
                                      designs. Throughout the course, students
                                      will explore the entire product design
                                      process, from research and ideation to
                                      prototyping and testing. They will learn
                                      how to effectively gather user insights,
                                      analyze market trends, and translate those
                                      findings into design concepts. Students
                                      will also gain proficiency in various
                                      design tools and software commonly used in
                                      the industry. The course typically covers
                                      the following topics: Introduction to
                                      product design: Overview of the product
                                      design field, its importance, and the role
                                      of a product designer. User research and
                                      analysis: Techniques for conducting user
                                      research, gathering insights, and
                                      analyzing user needs and behaviors.
                                      Ideation and concept generation: Creative
                                      brainstorming methods to generate and
                                      refine design concepts. Design principles
                                      and aesthetics: Understanding the
                                      fundamentals of visual design, typography,
                                      color theory, and composition. Prototyping
                                      and iteration: Techniques for creating
                                      prototypes to visualize and test design
                                      concepts, and incorporating feedback into
                                      the iterative design process. User testing
                                      and feedback: Methods for conducting user
                                      testing sessions, gathering feedback, and
                                      refining designs based on user insights.
                                      Design for manufacturing and production:
                                      Considerations for designing products that
                                      are manufacturable, considering materials,
                                      production processes, and cost
                                      constraints. Design communication:
                                      Developing skills in presenting and
                                      communicating design ideas to stakeholders
                                      and team members using visual tools and
                                      storytelling techniques. Throughout the
                                      course, students may work on individual
                                      and group projects to apply their
                                      knowledge and skills in real-world
                                      scenarios. They may also have
                                      opportunities to collaborate with peers
                                      and receive feedback from instructors to
                                      enhance their design abilities. By the end
                                      of the Product Design course, students
                                      should have a solid understanding of the
                                      product design process and be able to
                                      create thoughtful and user-centric
                                      designs. They can pursue careers as
                                      product designers, user experience (UX)
                                      designers, or interaction designers in
                                      various industries ranging from technology
                                      and consumer products to healthcare and
                                      automotive.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      overflow={"scroll"}
                                    >
                                      The Data Science course provides a
                                      comprehensive overview of the principles,
                                      techniques, and tools involved in
                                      analyzing and interpreting data to extract
                                      meaningful insights and make informed
                                      decisions. This course aims to equip
                                      students with the necessary skills and
                                      knowledge to navigate the field of data
                                      science and effectively apply data-driven
                                      approaches in various domains. Throughout
                                      the course, students will explore the
                                      entire data science lifecycle, from data
                                      collection and preprocessing to
                                      exploratory analysis, modeling, and
                                      visualization. They will learn how to
                                      leverage programming languages,
                                      statistical methods, and machine learning
                                      algorithms to extract valuable information
                                      from data sets. The course typically
                                      covers the following topics: Introduction
                                      to data science: Overview of the field,
                                      its applications, and the role of a data
                                      scientist. Data collection and
                                      preprocessing: Techniques for acquiring,
                                      cleaning, and transforming raw data into a
                                      usable format for analysis. Exploratory
                                      data analysis: Methods for understanding
                                      the structure and characteristics of data,
                                      identifying patterns, and visualizing
                                      insights. Statistical analysis: Applying
                                      statistical techniques to draw meaningful
                                      conclusions and make predictions from
                                      data. Machine learning: Introduction to
                                      machine learning algorithms, including
                                      supervised and unsupervised learning,
                                      regression, classification, and
                                      clustering. Data visualization: Creating
                                      effective visual representations of data
                                      to communicate insights and findings. Big
                                      data and scalable computing: Strategies
                                      for handling large-scale data sets and
                                      leveraging distributed computing
                                      frameworks. Ethical considerations and
                                      data privacy: Understanding the ethical
                                      implications of working with data,
                                      ensuring privacy, and maintaining data
                                      security. Throughout the course, students
                                      may work on hands-on projects and
                                      assignments that involve real-world
                                      datasets, allowing them to apply the
                                      concepts learned and gain practical
                                      experience in data analysis. They may also
                                      have opportunities to collaborate with
                                      classmates and receive feedback from
                                      instructors to improve their skills. By
                                      the end of the Data Science course,
                                      students should have a strong foundation
                                      in data analysis and be capable of
                                      tackling data-centric problems. They can
                                      pursue careers as data scientists, data
                                      analysts, or machine learning engineers in
                                      a wide range of industries such as
                                      finance, healthcare, e-commerce, and
                                      technology.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      overflow={"scroll"}
                                    >
                                      The DevOps course provides a comprehensive
                                      overview of the principles, practices, and
                                      tools used in the field of DevOps
                                      (Development and Operations). This course
                                      aims to equip students with the skills and
                                      knowledge required to bridge the gap
                                      between software development and
                                      operations teams, enabling them to
                                      collaborate effectively and streamline the
                                      software development lifecycle. Throughout
                                      the course, students will explore various
                                      aspects of DevOps, including continuous
                                      integration, continuous
                                      delivery/deployment, infrastructure as
                                      code, automation, and collaboration. They
                                      will learn how to implement DevOps
                                      practices and utilize tools to improve
                                      software development processes, enhance
                                      productivity, and ensure the quality and
                                      stability of software systems. The course
                                      typically covers the following topics:
                                      <br />
                                      <OrderedList>
                                        <ListItem>
                                          Introduction to DevOps: Overview of
                                          DevOps principles, its benefits, and
                                          its role in software development.
                                        </ListItem>
                                        <ListItem>
                                          Continuous Integration (CI):
                                          Techniques for automating code
                                          integration, running tests, and
                                          providing rapid feedback on code
                                          changes.
                                        </ListItem>
                                        <ListItem>
                                          Continuous Delivery/Deployment (CD):
                                          Strategies for automating the release
                                          and deployment of software to various
                                          environments.
                                        </ListItem>
                                        <ListItem>
                                          Infrastructure as Code (IaC): Using
                                          code and configuration management
                                          tools to provision and manage
                                          infrastructure resources.
                                        </ListItem>
                                        <ListItem>
                                          Configuration Management: Tools and
                                          techniques for managing and
                                          maintaining consistent configurations
                                          across environments. Containerization
                                          and Orchestration:
                                        </ListItem>
                                        <ListItem>
                                          Introduction to containerization
                                          technologies like Docker and container
                                          orchestration platforms like
                                          Kubernetes.
                                        </ListItem>
                                        <ListItem>
                                          Monitoring and Logging: Implementing
                                          tools and practices to monitor the
                                          performance, availability, and health
                                          of software systems.
                                        </ListItem>
                                        <ListItem>
                                          Collaboration and Communication:
                                          DevOps culture, collaboration
                                          techniques, and effective
                                          communication among teams. Throughout
                                          the course, students may work on
                                          hands-on projects and assignments to
                                          apply their knowledge in real-world
                                          scenarios. They may also have
                                          opportunities to work with various
                                          DevOps tools and technologies and gain
                                          practical experience in setting up and
                                          managing DevOps pipelines. By the end
                                          of the DevOps course, students should
                                          have a strong understanding of DevOps
                                          principles, practices, and tools. They
                                          will be well-equipped to pursue
                                          careers as DevOps engineers,
                                          automation specialists, or release
                                          managers in organizations that embrace
                                          DevOps methodologies for software
                                          development and operations.
                                        </ListItem>
                                      </OrderedList>
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      overflow={"scroll"}
                                    >
                                      The Web3 & Blockchain course provides a
                                      comprehensive overview of the fundamental
                                      concepts, technologies, and applications
                                      related to blockchain and Web3. This
                                      course aims to equip students with the
                                      knowledge and skills necessary to
                                      understand and leverage the potential of
                                      blockchain technology and decentralized
                                      applications. Throughout the course,
                                      students will explore various aspects of
                                      blockchain and Web3, including distributed
                                      ledger technology, smart contracts,
                                      decentralized applications (DApps), and
                                      the principles of decentralization. They
                                      will learn how blockchain technology can
                                      revolutionize industries such as finance,
                                      supply chain, healthcare, and more. The
                                      course typically covers the following
                                      topics: Introduction to blockchain:
                                      Overview of blockchain technology, its
                                      underlying principles, and its role in
                                      decentralization. Cryptography
                                      fundamentals: Understanding the
                                      cryptographic techniques used in
                                      blockchain systems, including hashing,
                                      digital signatures, and encryption.
                                      Distributed ledger technology: Exploring
                                      the concept of a distributed ledger and
                                      the consensus algorithms used to maintain
                                      the integrity of the blockchain. Smart
                                      contracts: Learning about programmable
                                      contracts and their execution on
                                      blockchain platforms like Ethereum.
                                      Decentralized application development:
                                      Building decentralized applications using
                                      blockchain platforms and frameworks.
                                      Tokenization and digital assets:
                                      Understanding the concept of tokenization
                                      and the creation of digital assets on the
                                      blockchain. Web3 and decentralized web:
                                      Exploring the vision of Web3, which aims
                                      to create a more user-centric and
                                      decentralized internet. Blockchain
                                      scalability and interoperability:
                                      Techniques and solutions to address
                                      scalability and interoperability
                                      challenges in blockchain networks.
                                      Blockchain security and governance:
                                      Discussing the security considerations and
                                      governance models in blockchain
                                      ecosystems. Throughout the course,
                                      students may work on hands-on projects and
                                      assignments, allowing them to apply their
                                      knowledge and gain practical experience in
                                      blockchain development. They may also have
                                      opportunities to explore real-world
                                      blockchain use cases and analyze the
                                      potential impact of blockchain technology
                                      on various industries. By the end of the
                                      Web3 & Blockchain course, students should
                                      have a solid understanding of blockchain
                                      technology and its applications in Web3.
                                      They will be well-prepared to pursue
                                      careers as blockchain developers, smart
                                      contract developers, blockchain
                                      consultants, or explore entrepreneurial
                                      opportunities in the rapidly evolving
                                      blockchain space.
                                    </Text>
                                  </TabPanel>
                                </TabPanels>
                              </Tabs>
                            </TabPanel>

                            {/* Requirements */}
                            <TabPanel>
                              <Tabs
                                variant='soft-rounded'
                                colorScheme='green'
                                justifyContent={"center"}
                              >
                                <TabList justifyContent={"center"}>
                                  <Tab>Software Engineering</Tab>
                                  <Tab>Mobile Engineering</Tab>
                                  <Tab>Product Design</Tab>
                                  <Tab>Data Science</Tab>
                                  <Tab>Devops</Tab>
                                  <Tab>Web3 Technology</Tab>
                                </TabList>

                                <TabPanels>
                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      pl={4}
                                    >
                                      <ol>
                                        <li>
                                          I am motivated and ready to learn.
                                        </li>
                                        <li>
                                          I can attend classes on weekdays or
                                          weekends, online or onsite for the
                                          period of 6-9months.
                                        </li>
                                        <li>
                                          I have access to a working computer.
                                        </li>
                                        <li>
                                          I have explored the tuition options
                                          and know the right option for me.
                                        </li>
                                        <li>
                                          I understand how challenging this
                                          journey is going to be, and I am
                                          motivated.
                                        </li>
                                      </ol>
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      pl={4}
                                    >
                                      <ol>
                                        <li>
                                          I am motivated and ready to learn.
                                        </li>
                                        <li>
                                          I can attend classes on weekdays or
                                          weekends, online or onsite for the
                                          period of 6-9months.
                                        </li>
                                        <li>
                                          I have access to a working computer.
                                        </li>
                                        <li>
                                          I have explored the tuition options
                                          and know the right option for me.
                                        </li>
                                        <li>
                                          I understand how challenging this
                                          journey is going to be, and I am
                                          motivated.
                                        </li>
                                      </ol>
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      pl={4}
                                    >
                                      <ol>
                                        <li>
                                          I am motivated and ready to learn.
                                        </li>
                                        <li>
                                          I can attend classes on weekdays or
                                          weekends, online or onsite for the
                                          period of 6-9months.
                                        </li>
                                        <li>
                                          I have access to a working computer.
                                        </li>
                                        <li>
                                          I have explored the tuition options
                                          and know the right option for me.
                                        </li>
                                        <li>
                                          I understand how challenging this
                                          journey is going to be, and I am
                                          motivated.
                                        </li>
                                      </ol>
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      pl={4}
                                    >
                                      <ol>
                                        <li>
                                          I am motivated and ready to learn.
                                        </li>
                                        <li>
                                          I can attend classes on weekdays or
                                          weekends, online or onsite for the
                                          period of 6-9months.
                                        </li>
                                        <li>
                                          I have access to a working computer.
                                        </li>
                                        <li>
                                          I have explored the tuition options
                                          and know the right option for me.
                                        </li>
                                        <li>
                                          I understand how challenging this
                                          journey is going to be, and I am
                                          motivated.
                                        </li>
                                      </ol>
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      pl={4}
                                    >
                                      <ol>
                                        <li>
                                          I am motivated and ready to learn.
                                        </li>
                                        <li>
                                          I can attend classes on weekdays or
                                          weekends, online or onsite for the
                                          period of 6-9months.
                                        </li>
                                        <li>
                                          I have access to a working computer.
                                        </li>
                                        <li>
                                          I have explored the tuition options
                                          and know the right option for me.
                                        </li>
                                        <li>
                                          I understand how challenging this
                                          journey is going to be, and I am
                                          motivated.
                                        </li>
                                      </ol>
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                      pl={4}
                                    >
                                      <ol>
                                        <li>
                                          I am motivated and ready to learn.
                                        </li>
                                        <li>
                                          I can attend classes on weekdays or
                                          weekends, online or onsite for the
                                          period of 6-9months.
                                        </li>
                                        <li>
                                          I have access to a working computer.
                                        </li>
                                        <li>
                                          I have explored the tuition options
                                          and know the right option for me.
                                        </li>
                                        <li>
                                          I understand how challenging this
                                          journey is going to be, and I am
                                          motivated.
                                        </li>
                                      </ol>
                                    </Text>
                                  </TabPanel>
                                </TabPanels>
                              </Tabs>
                            </TabPanel>

                            {/* Curriculum */}
                            <TabPanel>
                              <Tabs
                                variant='soft-rounded'
                                colorScheme='green'
                                justifyContent={"center"}
                              >
                                <TabList justifyContent={"center"}>
                                  <Tab>Software Engineering</Tab>
                                  <Tab>Mobile Engineering</Tab>
                                  <Tab>Product Design</Tab>
                                  <Tab>Data Science</Tab>
                                  <Tab>Devops</Tab>
                                  <Tab>Web3 Technology</Tab>
                                </TabList>

                                <TabPanels>
                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Problem Solving - Algorithms - Flowcharts
                                      - Software Engineering Design Pattern -
                                      Challenges on hackerrank and leet code -
                                      git - Collaborative and peer-to-peer
                                      coding - Project Management Tools - Trello{" "}
                                      <br /> <br />
                                      Backend Development - Javascript -
                                      Typescript - ESNext - Modularity and
                                      classes - Asynchronous Javascript - Event
                                      Loop - Node inbuilt APIs - Streams - Node
                                      servers - ExpressJS - Middlewares - Rest
                                      APIs - Authentication and Authorization -
                                      SQL - MongoDB <br /> <br />
                                      Frontend Development - Html - Css - Sass -
                                      Bootstrap - Tailwind - Client-Side
                                      Rendering and React, React Hooks and State
                                      management - Redux - React Routing,
                                      Webpack, Consuming API, Frontend Testing,
                                      End to End Testing <br />
                                      <br />
                                      DevOps - Mockito - Integration testing -
                                      Servers - Linux - CICD - Mocha - PM2 -
                                      Azure - AWS - DNS mapping
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Problem Solving - Algorithms - Flowcharts
                                      - Software Engineering Design Pattern -
                                      Challenges on hackerrank and leet code -
                                      git - Collaborative and peer-to-peer
                                      coding - Project Management Tools - Trello{" "}
                                      <br />
                                      Backend Development - Javascript -
                                      Typescript - ESNext - Modularity and
                                      classes - Asynchronous Javascript - Event
                                      Loop - Node inbuilt APIs - Streams - Node
                                      servers - ExpressJS - Middlewares - Rest
                                      APIs - Authentication and Authorization -
                                      SQL - MongoDB <br />
                                      Frontend Development - Html - Css - Sass -
                                      Bootstrap - Tailwind - Client-Side
                                      Rendering and React, React Hooks and State
                                      management - Redux - React Routing,
                                      Webpack, Consuming API, Frontend Testing,
                                      End to End Testing <br />
                                      DevOps - Mockito - Integration testing -
                                      Servers - Linux - CICD - Mocha - PM2 -
                                      Azure - AWS - DNS mapping
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Problem Solving - Algorithms - Flowcharts
                                      - Software Engineering Design Pattern -
                                      Challenges on hackerrank and leet code -
                                      git - Collaborative and peer-to-peer
                                      coding - Project Management Tools - Trello{" "}
                                      <br />
                                      Backend Development - Javascript -
                                      Typescript - ESNext - Modularity and
                                      classes - Asynchronous Javascript - Event
                                      Loop - Node inbuilt APIs - Streams - Node
                                      servers - ExpressJS - Middlewares - Rest
                                      APIs - Authentication and Authorization -
                                      SQL - MongoDB <br />
                                      Frontend Development - Html - Css - Sass -
                                      Bootstrap - Tailwind - Client-Side
                                      Rendering and React, React Hooks and State
                                      management - Redux - React Routing,
                                      Webpack, Consuming API, Frontend Testing,
                                      End to End Testing <br />
                                      DevOps - Mockito - Integration testing -
                                      Servers - Linux - CICD - Mocha - PM2 -
                                      Azure - AWS - DNS mapping
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Problem Solving - Algorithms - Flowcharts
                                      - Software Engineering Design Pattern -
                                      Challenges on hackerrank and leet code -
                                      git - Collaborative and peer-to-peer
                                      coding - Project Management Tools - Trello{" "}
                                      <br />
                                      Backend Development - Javascript -
                                      Typescript - ESNext - Modularity and
                                      classes - Asynchronous Javascript - Event
                                      Loop - Node inbuilt APIs - Streams - Node
                                      servers - ExpressJS - Middlewares - Rest
                                      APIs - Authentication and Authorization -
                                      SQL - MongoDB <br />
                                      Frontend Development - Html - Css - Sass -
                                      Bootstrap - Tailwind - Client-Side
                                      Rendering and React, React Hooks and State
                                      management - Redux - React Routing,
                                      Webpack, Consuming API, Frontend Testing,
                                      End to End Testing <br />
                                      DevOps - Mockito - Integration testing -
                                      Servers - Linux - CICD - Mocha - PM2 -
                                      Azure - AWS - DNS mapping
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Problem Solving - Algorithms - Flowcharts
                                      - Software Engineering Design Pattern -
                                      Challenges on hackerrank and leet code -
                                      git - Collaborative and peer-to-peer
                                      coding - Project Management Tools - Trello{" "}
                                      <br />
                                      Backend Development - Javascript -
                                      Typescript - ESNext - Modularity and
                                      classes - Asynchronous Javascript - Event
                                      Loop - Node inbuilt APIs - Streams - Node
                                      servers - ExpressJS - Middlewares - Rest
                                      APIs - Authentication and Authorization -
                                      SQL - MongoDB <br />
                                      Frontend Development - Html - Css - Sass -
                                      Bootstrap - Tailwind - Client-Side
                                      Rendering and React, React Hooks and State
                                      management - Redux - React Routing,
                                      Webpack, Consuming API, Frontend Testing,
                                      End to End Testing <br />
                                      DevOps - Mockito - Integration testing -
                                      Servers - Linux - CICD - Mocha - PM2 -
                                      Azure - AWS - DNS mapping
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Problem Solving - Algorithms - Flowcharts
                                      - Software Engineering Design Pattern -
                                      Challenges on hackerrank and leet code -
                                      git - Collaborative and peer-to-peer
                                      coding - Project Management Tools - Trello{" "}
                                      <br />
                                      Backend Development - Javascript -
                                      Typescript - ESNext - Modularity and
                                      classes - Asynchronous Javascript - Event
                                      Loop - Node inbuilt APIs - Streams - Node
                                      servers - ExpressJS - Middlewares - Rest
                                      APIs - Authentication and Authorization -
                                      SQL - MongoDB <br />
                                      Frontend Development - Html - Css - Sass -
                                      Bootstrap - Tailwind - Client-Side
                                      Rendering and React, React Hooks and State
                                      management - Redux - React Routing,
                                      Webpack, Consuming API, Frontend Testing,
                                      End to End Testing <br />
                                      DevOps - Mockito - Integration testing -
                                      Servers - Linux - CICD - Mocha - PM2 -
                                      Azure - AWS - DNS mapping
                                    </Text>
                                  </TabPanel>
                                </TabPanels>
                              </Tabs>
                            </TabPanel>

                            {/* Payment & Sponsorship */}
                            <TabPanel>
                              <Tabs
                                variant='soft-rounded'
                                colorScheme='green'
                                justifyContent={"center"}
                              >
                                <TabList justifyContent={"center"}>
                                  <Tab>Software Engineering</Tab>
                                  <Tab>Mobile Engineering</Tab>
                                  <Tab>Product Design</Tab>
                                  <Tab>Data Science</Tab>
                                  <Tab>Devops</Tab>
                                  <Tab>Web3 Technology</Tab>
                                </TabList>

                                <TabPanels>
                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      The Self Sponsorship Plan - SSP is
                                      (Pay-Learn-Earn) action plan where
                                      prospective learners either sponsor
                                      themselves and/or a third-party
                                      sponsorship for the bootcamp.
                                      <br />
                                      The Financier Investment Plan - FIP is
                                      (Learn-Earn-Pay). Here, Zulfah and its
                                      financier partners will agree to sponsor
                                      learners throughout the programme under
                                      which the learner agree to pay XY% of
                                      their post-zulfah academy income for
                                      XYmonths as agreed by the learner and the
                                      financier partner.
                                      <br />
                                      For more info, you can reach us at
                                      info@zulfahgroup.com or at +2348115371014
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      The Self Sponsorship Plan - SSP is
                                      (Pay-Learn-Earn) action plan where
                                      prospective learners either sponsor
                                      themselves and/or a third-party
                                      sponsorship for the bootcamp.
                                      <br />
                                      The Financier Investment Plan - FIP is
                                      (Learn-Earn-Pay). Here, Zulfah and its
                                      financier partners will agree to sponsor
                                      learners throughout the programme under
                                      which the learner agree to pay XY% of
                                      their post-zulfah academy income for
                                      XYmonths as agreed by the learner and the
                                      financier partner.
                                      <br />
                                      For more info, you can reach us at
                                      info@zulfahgroup.com or at +2348115371014
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      The Self Sponsorship Plan - SSP is
                                      (Pay-Learn-Earn) action plan where
                                      prospective learners either sponsor
                                      themselves and/or a third-party
                                      sponsorship for the bootcamp.
                                      <br />
                                      The Financier Investment Plan - FIP is
                                      (Learn-Earn-Pay). Here, Zulfah and its
                                      financier partners will agree to sponsor
                                      learners throughout the programme under
                                      which the learner agree to pay XY% of
                                      their post-zulfah academy income for
                                      XYmonths as agreed by the learner and the
                                      financier partner.
                                      <br />
                                      For more info, you can reach us at
                                      info@zulfahgroup.com or at +2348115371014
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      The Self Sponsorship Plan - SSP is
                                      (Pay-Learn-Earn) action plan where
                                      prospective learners either sponsor
                                      themselves and/or a third-party
                                      sponsorship for the bootcamp.
                                      <br />
                                      The Financier Investment Plan - FIP is
                                      (Learn-Earn-Pay). Here, Zulfah and its
                                      financier partners will agree to sponsor
                                      learners throughout the programme under
                                      which the learner agree to pay XY% of
                                      their post-zulfah academy income for
                                      XYmonths as agreed by the learner and the
                                      financier partner.
                                      <br />
                                      For more info, you can reach us at
                                      info@zulfahgroup.com or at +2348115371014
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      The Self Sponsorship Plan - SSP is
                                      (Pay-Learn-Earn) action plan where
                                      prospective learners either sponsor
                                      themselves and/or a third-party
                                      sponsorship for the bootcamp.
                                      <br />
                                      The Financier Investment Plan - FIP is
                                      (Learn-Earn-Pay). Here, Zulfah and its
                                      financier partners will agree to sponsor
                                      learners throughout the programme under
                                      which the learner agree to pay XY% of
                                      their post-zulfah academy income for
                                      XYmonths as agreed by the learner and the
                                      financier partner.
                                      <br />
                                      For more info, you can reach us at
                                      info@zulfahgroup.com or at +2348115371014
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      The Self Sponsorship Plan - SSP is
                                      (Pay-Learn-Earn) action plan where
                                      prospective learners either sponsor
                                      themselves and/or a third-party
                                      sponsorship for the bootcamp.
                                      <br />
                                      The Financier Investment Plan - FIP is
                                      (Learn-Earn-Pay). Here, Zulfah and its
                                      financier partners will agree to sponsor
                                      learners throughout the programme under
                                      which the learner agree to pay XY% of
                                      their post-zulfah academy income for
                                      XYmonths as agreed by the learner and the
                                      financier partner.
                                      <br />
                                      For more info, you can reach us at
                                      info@zulfahgroup.com or at +2348115371014
                                    </Text>
                                  </TabPanel>
                                </TabPanels>
                              </Tabs>
                            </TabPanel>

                            {/* Next application */}
                            <TabPanel>
                              <Tabs
                                variant='soft-rounded'
                                colorScheme='green'
                                justifyContent={"center"}
                              >
                                <TabList justifyContent={"center"}>
                                  <Tab>Software Engineering</Tab>
                                  <Tab>Mobile Engineering</Tab>
                                  <Tab>Product Design</Tab>
                                  <Tab>Data Science</Tab>
                                  <Tab>Devops</Tab>
                                  <Tab>Web3 Technology</Tab>
                                </TabList>

                                <TabPanels>
                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Applications are now opened today, 15th
                                      May 2023 for the next cohorts.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Applications are now opened today, 15th
                                      May 2023 for the next cohorts.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Applications are now opened today, 15th
                                      May 2023 for the next cohorts.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Applications are now opened today, 15th
                                      May 2023 for the next cohorts.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Applications are now opened today, 15th
                                      May 2023 for the next cohorts.
                                    </Text>
                                  </TabPanel>

                                  <TabPanel>
                                    <Text
                                      lineHeight={"2"}
                                      style={{ fontWeight: "normal" }}
                                      fontSize={"17"}
                                      h={450}
                                    >
                                      Applications are now opened today, 15th
                                      May 2023 for the next cohorts.
                                    </Text>
                                  </TabPanel>
                                </TabPanels>
                              </Tabs>
                            </TabPanel>
                          </TabPanels>
                        </Stack>
                      </Flex>
                    </Heading>{" "}
                    <hr />
                    {/* <hr /> */}
                    {/* {
                      (item.title === 'Next Application' && `${new Date().getMonth() + 1}` === 9 && 
                      `${new Date().getDate() === 26}`) ? 
                     
                        <>
                          <Text lineHeight={"2"}>{item.body_out}</Text>        
                          <Button
                          variant={"solid"}
                          bg={"brand.fuscia"}
                          w="150px"
                          color={"white"}
                          size={"lg"}
                          borderRadius={4}
                        >
                          <ReactRouterLink to="/application">Enrol now</ReactRouterLink>
                            </Button>
                       </>
                      
                        : 
                        <Text style={{  whiteSpace: "pre-line"}}lineHeight={"2"}>{item.body}</Text>
                      
                    } */}
                  </Stack>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Stack>
        {/** Software Bootcamp */}

        {/*  Meet the Team */}
        <Stack
          w={"100%"}
          alignItems={"center"}
          paddingTop={{ base: "50px", lg: "90px" }}
          bg={"whiteAlpha.100"}
        >
          <Heading w={"80%"} textAlign={{ base: "center", lg: "center" }}>
            Meet the team
          </Heading>
          <br />
          <Flex
            direction={{ base: "column", lg: "row" }}
            width={{ base: "80%", lg: "80%" }}
            justifyContent={"space-around"}
            alignContent={"center"}
          >
            {team.map((member) => (
              <Stack
                key={member.img}
                spacing={{ base: 4, lg: 8 }}
                marginBottom={{ base: 9, lg: 0 }}
              >
                <Box
                  boxSize={"287px"}
                  backgroundImage={member.img}
                  backgroundSize={"contain"}
                  backgroundPosition='center'
                  backgroundRepeat='no-repeat'
                  borderRadius={"500px"}
                  backgroundColor={"pink"}
                  marginLeft={{
                    base: "0px",
                    sm: "100px",
                    md: "200px",
                    lg: "0px",
                  }}
                ></Box>
                <Stack spacing={2}>
                  <Stack spacing={0}>
                    <Heading style={{ textAlign: "center" }} fontSize={"18px"}>
                      {member.name}
                    </Heading>
                    {/* <Text fontSize={"12px"}>Chief Technical Officer</Text> */}
                  </Stack>
                  <Flex
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {member.linkedIn !== "" ? (
                      <Link href={member.linkedIn} isExternal>
                        <Icon
                          as={AiFillLinkedin}
                          w={4}
                          h={4}
                          color={"brand.blue"}
                        />
                      </Link>
                    ) : (
                      <Icon
                        as={AiFillLinkedin}
                        w={4}
                        h={4}
                        color={"brand.blue"}
                      />
                    )}
                    <Icon
                      as={AiFillFacebook}
                      w={4}
                      h={4}
                      color={"brand.blue"}
                    />
                  </Flex>
                </Stack>
              </Stack>
            ))}
          </Flex>
        </Stack>
        {/*  Meet the Team */}

        {/*  Payment Options */}
        <Stack w={"100%"} alignItems={{ base: "center", lg: "flex-start" }}>
          <Flex
            w={"85%"}
            direction={{ base: "column", lg: "row" }}
            justifyContent={{ base: "space-around", lg: "space-between" }}
            bg={"white"}
            mt={{ base: "50px", lg: "100px" }}
          >
            <Stack
              display={{ sm: "none", md: "flex" }}
              w={{ base: "100%", lg: "530px" }}
              h={{ base: "auto", lg: "541px" }}
              alignItems={"flex-end"}
              justifyContent={"space-around"}
              bg={"brand.deepgrey"}
              borderTopLeftRadius={{ base: "15px", lg: "0" }}
              borderBottomLeftRadius={{ base: "15px", lg: "0" }}
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              py={{ base: "60px", lg: "40px" }}
              spacing={4}
            >
              <Flex
                width={"350px"}
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                <Flex
                  py={8}
                  width={{ base: "90px", lg: "114px" }}
                  h={{ base: "90px", lg: "114px" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"50%"}
                  bg={"white"}
                >
                  <Icon as={Tuition} width={"57px"} h={"57px"} />
                </Flex>
                <Stack color={"white"} w={{ base: "50%", lg: "60%" }}>
                  <Heading>500,000</Heading>
                  <Text>Frontend - Backend</Text>
                  <Text>6 Months</Text>
                </Stack>
              </Flex>
              <Flex
                width={"350px"}
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                <Flex
                  py={8}
                  width={{ base: "90px", lg: "114px" }}
                  h={{ base: "90px", lg: "114px" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"50%"}
                  bg={"white"}
                >
                  <Icon as={Tuition} width={"57px"} h={"57px"} />
                </Flex>
                <Stack color={"white"} w={{ base: "40%", lg: "60%" }}>
                  <Heading>850,000</Heading>
                  <Text>Fullstack</Text>
                  <Text>9 Months</Text>
                </Stack>
              </Flex>
              <Flex
                width={"350px"}
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                <Flex
                  py={8}
                  width={{ base: "90px", lg: "114px" }}
                  h={{ base: "90px", lg: "114px" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"50%"}
                  bg={"white"}
                >
                  <Icon as={Tuition} width={"57px"} h={"57px"} />
                </Flex>
                <Stack color={"white"} w={{ base: "40%", lg: "60%" }}>
                  <Heading>500,000</Heading>
                  <Text>Mobile Development</Text>
                  <Text>6 Months</Text>
                </Stack>
              </Flex>
            </Stack>

            <Stack m={{ base: "20px", md: "20px", lg: "20px" }}></Stack>
            <Stack
              w={{ base: "100%", lg: "100%" }}
              mt={{ base: "20px", lg: "none" }}
            >
              <Heading py={"15px"}>Payment & Sponsorship</Heading>
              <br />
              <Tabs variant='unstyled'>
                <TabList>
                  <Tab
                    color='brand.deepgrey'
                    bg='brand.lightpink'
                    _focus={{ boxShadow: "none" }}
                    _selected={{
                      color: "brand.lightpink",
                      bg: "brand.deepgrey",
                    }}
                    borderWidth={"1px"}
                    borderStyle={"solid"}
                    borderColor={"brand.deepgrey"}
                    borderTopLeftRadius={4}
                    borderBottomLeftRadius={4}
                    py={{ base: "10px", lg: "10px" }}
                    px={{ base: "20px", md: "50px", lg: "70px" }}
                  >
                    Self Sponsorship Plan - SSP
                  </Tab>
                  <Tab
                    color='brand.deepgrey'
                    bg='brand.lightpink'
                    _focus={{ boxShadow: "none" }}
                    _selected={{
                      color: "brand.lightpink",
                      bg: "brand.deepgrey",
                    }}
                    borderWidth={"1px"}
                    borderStyle={"solid"}
                    borderColor={"brand.deepgrey"}
                    borderTopRightRadius={4}
                    py={{ base: "10px", lg: "10px" }}
                    px={{ base: "20px", md: "50px", lg: "70px" }}
                  >
                    Financial Investment Plan - FIP
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel px={0} py={8}>
                    <Text mb={"40px"}>
                      The Self Sponsorship Plan - SSP is a (Pay-Learn-Earn)
                      payment plan where prospective learners either sponsor
                      themselves and/or a third-party from the learner help with
                      the training costs.
                      <br />
                      <br />
                      We are confident that our training and career support will
                      lead to a high paying job for the learner after going
                      through our 6-9months intensive training
                    </Text>
                    <ReactRouterLink to='/application'>
                      <Flex
                        w={"100%"}
                        alignItems={"center"}
                        fontSize={"18px"}
                        color={"brand.fuscia"}
                        fontWeight={"bold"}
                      >
                        <Text mr={2}>Enroll now</Text>{" "}
                        <BsArrowRight size={"20px"} />
                      </Flex>
                    </ReactRouterLink>
                  </TabPanel>
                  <TabPanel px={0} py={8}>
                    <Text mb={"40px"}>
                      <p>
                        Learners who cannot afford the bootcamp fees can opt for
                        the FIP(Financier Investment Plan):
                        <br />
                        <br />
                        This is a (Learn-Earn-Pay) action plan. <br />
                        <br />
                        Here, Zulfah and its financier partners will agree to
                        sponsor learners throughout the programme under which
                        the student agrees to pay XY% of your post-training
                        income for XYmonths as agreed by the candidate and the
                        financier partner. Click{" "}
                        <ReactRouterLink to='#'>here</ReactRouterLink> for more
                        details
                      </p>
                    </Text>
                    <ReactRouterLink to='/application'>
                      <Flex
                        w={"100%"}
                        alignItems={"center"}
                        fontSize={"18px"}
                        color={"brand.fuscia"}
                        fontWeight={"bold"}
                      >
                        <Text mr={2}>Enroll now</Text>{" "}
                        <BsArrowRight size={"20px"} />
                      </Flex>
                    </ReactRouterLink>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </Flex>
        </Stack>
        {/*  Payment Options */}

        {/*  Company our student works */}
        <Stack
          w={"100%"}
          mb={0}
          mt={50}
          alignItems={{ base: "center" }}
          display={{ base: "none", md: "flex" }}
        >
          <Heading
            w={"80%"}
            fontSize={{ base: "20px" }}
            textAlign={{ base: "center", lg: "center" }}
          >
            Companies Our Graduates Work For
          </Heading>
          <br />
          <Flex
            w={"85%"}
            direction={{ base: "row", md: "row", lg: "row" }}
            bg={"white"}
            mt={{ base: "20px", lg: "100px" }}
          >
            <Stack w={{ base: "100%" }}>
              <Box
                display={{ base: "flex" }}
                alignItems={"center"}
                justifyContent={"space-around"}
                w={"100%"}
                h={"155px"}
                bg={"brand.white"}
                borderRadius='12px'
                boxShadow={"lg"}
              >
                <HStack h={"70%"} pl={4}>
                  <Stack
                    style={{ alignItems: "center", justifyContent: "center" }}
                    spacing={4}
                    pl={4}
                    pr={4}
                    w={"20%"}
                  >
                    <Image
                      src={Meristem}
                      w={{ md: "100%", lg: "70%" }}
                      alt='Zulfah'
                    />
                  </Stack>
                  <Divider
                    orientation='vertical'
                    bg={"brand.deepgrey"}
                    color={"brand.deepgrey"}
                  />
                  <Stack
                    style={{ alignItems: "center", justifyContent: "center" }}
                    spacing={4}
                    pl={4}
                    pr={4}
                    w={"20%"}
                  >
                    <Image
                      src={Interswitch}
                      w={{ md: "100%", lg: "80%" }}
                      alt='Zulfah'
                    />
                  </Stack>
                  <Divider orientation='vertical' />

                  <Stack
                    style={{ alignItems: "center", justifyContent: "center" }}
                    spacing={4}
                    pl={4}
                    pr={4}
                    w={"20%"}
                  >
                    <Image
                      src={Flutterwave}
                      alt='Zulfah'
                      w={{ md: "100%", lg: "100%" }}
                    />
                  </Stack>

                  <Divider orientation='vertical' />

                  <Stack
                    style={{ alignItems: "center", justifyContent: "center" }}
                    spacing={4}
                    pl={4}
                    pr={4}
                    w={"20%"}
                  >
                    <Image
                      h={50}
                      w={{ md: "100%", lg: "70%" }}
                      alignItems='start'
                      src={HerVest}
                      alt='Zulfah'
                    />
                  </Stack>
                  <Divider orientation='vertical' />
                  <Stack
                    style={{ alignItems: "center", justifyContent: "center" }}
                    spacing={4}
                    pl={4}
                    pr={4}
                    w={"15%"}
                  >
                    <Image
                      src={logo}
                      w={{ md: "100%", lg: "100%" }}
                      alt='Zulfah'
                    />
                  </Stack>
                </HStack>
              </Box>
            </Stack>
          </Flex>
        </Stack>
        {/*  Company our student works */}

        {/*  Graduate Testimonials */}
        <Stack w={"100%"} mb={10} alignItems={{ base: "center" }}>
          <Flex
            w={"85%"}
            direction={{ base: "column", lg: "row" }}
            bg={"white"}
            mt={{ base: "30px", md: "10px" }}
          >
            <Stack w={{ base: "100%" }} mt={{ base: "50px" }}>
              <Heading
                py={"15px"}
                fontSize={{ base: "20px" }}
                textAlign={"center"}
              >
                Our Alumni Speak
              </Heading>

              <Box bg={useColorModeValue("gray.100", "gray.700")}>
                <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    spacing={{ base: 10, md: 4, lg: 10 }}
                  >
                    {students_reviews.map((testimonial) => (
                      <Testimonial>
                        <TestimonialContent>
                          {/* <TestimonialHeading>Efficient Collaborating</TestimonialHeading> */}
                          <TestimonialText>
                            {testimonial.review}
                          </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                          src={""}
                          name={`${testimonial.name}`}
                          title={`${testimonial.work}`}
                          company={`${testimonial.company}`}
                        />
                      </Testimonial>
                    ))}
                  </Stack>
                </Container>
              </Box>
            </Stack>
          </Flex>
        </Stack>
        {/*  Graduate Testimonials */}

        {/*  Payment Options */}
        {/* <Stack w={"100%"} alignItems={{ base: "center", lg: "flex-start" }}>
          <Flex
            w={"85%"}
            direction={{ base: "column", lg: "row" }}
            bg={"white"}
            mb={{ base: "50px", lg: "100px" }}
          >
            <Stack
              w={{ base: "100%", lg: "541px" }}
              mt={{ base: "50px", lg: "none" }}
            >
              <Heading
                py={"15px"}
                textAlign={"center"}>
                Testimonials
              </Heading>
              <Tabs isFitted variant='enclosed'>
                <TabList mb='1em'>
                  <Tab>One</Tab>
                  <Tab>Two</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <p>one!</p>
                  </TabPanel>
                  <TabPanel>
                    <p>two!</p>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </Flex>
        </Stack> */}
        {/*  Payment Options */}

        <FAQs />
        <ApplicationCTA />
      </Box>
      <Footer />
    </Box>
  )
}
