export const countries = [
  { name: "Nigeria" },
  { name: "Ghana" },
  { name: "Cameroon" },
  { name: "Algeria" },
  { name: "Ghana" },
  { name: "Ghana" },
  { name: "Ghana" },
  { name: "Ghana" },
  { name: "Ghana" },
  { name: "Ghana" },
  { name: "Ghana" },
  { name: "Others" },
];

export const processStages = [
  {
    heading: `Step 1 - Personal details`,
    summary_: `This section, we would like to know about you . This would make us understand you better.`,
  },
  {
    heading: "Step 2 - Education details",
    summary_: "This might not be piority for us, but in the past few years, we want to know what you have been up to",
  },
  {
    heading: "Step 3 - Payment & Sponsorships",
    summary_:`Because opportunity is not Here, we discuss the various payment options we have .`,
  },
  {
    heading: "Step 4 - Programme | Type ",
    summary_: "Check that everything is set before hitting that submit button.",
  },
  {
    heading: "Step 5 - Application Submission ",
    summary_: "Check that everything is set before hitting that submit button.",
  },
];

export const qualifications = ["SSCE","OND", "HND", "BSc", "MSC", "PhD"];

export const devYears = ["0 years", "1-2 years", "3+ years"];

export const paymentOptions = [
  { name: "Self Sponsorship Plan - SSP", value: "SIP" }
  // { name: "Financier Investment Plan - FIP", value: "FIP" },
];

export const hireFaqs = [
  {
    title: " What curriculum do your grads go through? ",
    body: "We train our learners on fullstack engineering, so they can comfortably write both frontend and backend excellently. If you'd like more information, please reach out to info@zulfahgroup.com.",
  },
  {
    title: "What do I pay for wanting to hire your graduate",
    body: "Our partnership team is dedicated to understanding your soft and technical needs. We defintely can discuss and work things out. Kindly reach us directly at info@zulfahgroup.com",
  },
  {
    title: "When can we hire grads from your program? ",
    body: "Our graduates are ready to interview in April and October. You can reach out to us ",
  }
];

export const hearAboutUs = [
  { name: "Facebook" },
  { name: "LinkedIn" },
  { name: "Twitter" },
  { name: "An Alumnus" },
  { name: "Google search" },
];

export const tableOfContent = [
  {
    title: "Overview",
    body: `Our development & engineering courses are designed to make you world-class fit, teaching you the technical and professional skills hiring managers need most. You’ll attend classes led by industry experts who will teach you the foundations of full-stack development using HTML, CSS, JavaScript, Typescript, ReactJs. 
    You’ll also develop web servers using NodeJs,ExpressJS, RDBMS including MySQL and PostgreSQL while and applying various fundamental computer science concepts in your development. Plus, you’ll have the chance to build and contribute to real products with a team of other learners that you can showcase in your portfolio.\n
    We promote collaborative learning, which will be seen in our usage of git, Trello, and other tools in the software engineering space. Through an agile delivery methodology, mentor matching, and leadership modules, our elite engineers are equipped with techniques and a leadership mindset that enables them to work competitively in any team globally.
   `
  },
  {
    title: "Requirements Checklist",
    body: `1. I am motivated and ready to learn\n 2. I can attend classes on weekdays or weekends, online or onsite for the period of 6months.\n 3. I have access to a working computer\n 4. I have explored the tuition options and know the right option for me.\n5. I understand how challenging this journey is going to be, and I am motivated.\n
    `
  },
  
  {
    title: "Curriculum",
    body: `Problem Solving - Algorithms - Flowcharts - Software Engineering Design Pattern - Challenges on hackerrank and leet code - git - Collaborative and peer-to-peer coding - Project Management Tools - Trello  \n
    Backend Development - Javascript - Typescript - ESNext - Modularity and classes - Asynchronous Javascript - Event Loop - Node inbuilt APIs - Streams - Node servers - ExpressJS - Middlewares - Rest APIs - Authentication and Authorization - SQL - MongoDB\n
    Frontend Development - Html - Css - Sass - Bootstrap - Tailwind - Client-Side Rendering and React, React Hooks and State management - Redux - React Routing, Webpack, Consuming API, Frontend Testing, End to End Testing \n
    DevOps - Mockito - Integration testing - Servers - Linux - CICD - Mocha - PM2 - Azure - AWS - DNS mapping `
  },
  {
    title: "Payment & Sponsorship",
    body: ` The Self Sponsorship Plan - SSP is (Pay-Learn-Earn) action plan where prospective learners either sponsor themselves and/or a third-party sponsorship for the bootcamp.\n
    The Financier Investment Plan - FIP is (Learn-Earn-Pay). Here, Zulfah and its financier partners will agree to sponsor learners throughout the programme under which the learner agree to pay XY% of their post-zulfah academy income for XYmonths as agreed by the learner and the financier partner.
   \n For more info, you can reach us at info@zulfahgroup.com or at +2348115371014`,
  },
  {
    title: "Next Application",
    body: `Brace up guys 🦾💪🏾, Application opens 8th May, 2023 .`,
    body_out: `Applications are now opened today, 8th May 2023 for the next cohorts .`,
  },
];

export const students_reviews = [
  {
    name: "Abass",
    work: "Software Engineer",
    company: "Flutterwave",
    review: `Thanks to Zulfah,  my life will forever be changed. I went from zero knowledge of tech to a full stack developer. I loved this program and everything it's done for me.From zero knowlegde to joining flutterwave as a software engineer`
  },
  {
    name: "Hikmah",
    work: "Frontend Engineer",
    company: "HerVest",
    review: `After my Undergrad, it was not clear what i wanted to do. I opted for a career switch and Zulfah has helped me build a career in Software Engineering. I presently work as a frontend engineer at herVest and it's been really amazing journey from biochemistry to Software developer. I can confidently say I made the right decision.`
  },
  {
    name: "Faruq",
    work: "Fullstack Engineer",
    company: "Zulfah",
    review: ` It was an amazing 6 months of intense learning, unlearning, and relearning. Indeed, the jounery was worth it all along, learning with @zulfah from zero to a ninja level. Cheers to building solutions!.`,
  }
];

export const programmeTypes = [
  { name: "Weekdays", value: "weekdays" },
  { name: "Weekends", value: "weekends" },
]

export const programmes = [
  { name: "Frontend Development", value: "frontend" },
  { name: "Backend Development", value: "backend" },
  { name: "Fullstack Development", value: "fullstack" },
  // { name: "Data Science", value: "data_science" },
  // { name: "Mobile Development", value: "mobile" },
  { name: "Cybersecurity", value: "cybersecurity" },
  { name: "Blockchain & Web3", value: "web3" },
  { name: "DevOps", value: "devops" },
]